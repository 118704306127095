<template>
  <div>
    <Header></Header>
    <div class="container mx-auto py-4">
      <breadcrumb></breadcrumb>
      <div class="border border-gray-400 rounded-3xl mt-2 px-6 pt-4 pb-6">
        <div class="flex items-center">
          <div class="text-primary font-bold text-xl">Special for you</div>
          <div class="flex items-center bg-gray-100 rounded-lg w-40 md:w-64 px-2 py-1 ml-6 md:ml-8">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            <input v-model="search" class="bg-transparent focus:outline-none w-full ml-2" placeholder="Search package" />
          </div>
        </div>
        <div class="grid-cols-1 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-8">
          <div v-for="offer in filteredOffers" :key="offer.id"
            class="rounded-xl overflow-hidden border border-gray-200 shadow-lg">
            <div class="font-medium text-sm bg-primary text-white text-center py-1">
              {{ offer.nama }}
            </div>
            <div class="grid grid-cols-5 h-44 grd">
              <div class="col-span-2">
                <div class="p-2 relative">
                  <img :src="offer.galeri[0].foto" class="object-cover w-full h-28 rounded-xl border border-gray-300" />
                  <span class="absolute bottom-2 right-4 text-2xl cursor-pointer" @click="toggleWishlist(offer)" :class="{
                    'text-yellow': offer.idw != '0',
                    'text-white': offer.idw == '0',
                  }"><font-awesome-icon icon="fa-solid fa-heart" /></span>
                </div>
              </div>
              <div class="col-span-3 pt-2 pr-2 flex flex-col justify-between">
                <div>
                  <div class="text-sm font-semibold">{{ offer.nama_toko }}</div>
                  <div class="flex justify-between mt-2">
                    <div class="flex items-start whitespace-nowrap">
                      <font-awesome-icon icon="fa-regular fa-clock" size="sm" />
                      <div class="text-xs font-medium ml-1">
                        {{ offer.durasi }}
                        {{ offer.durasi == 1 ? "Day" : "Days" }}
                      </div>
                    </div>
                    <div class="flex items-center justify-end">
                      <img :src="getLevelImage(offer.level)" class="w-1/4" />
                      <div class="text-xs font-medium ml-1">
                        {{ getLevelText(offer.level) }}
                      </div>
                    </div>
                  </div>
                  <div class="text-2xs mt-2 font-medium">
                    <div>Categories :</div>
                    <div>{{ offer.category }}</div>
                  </div>
                  <div class="text-xs mt-2 font-medium">
                    Start from :
                    <span class="text-sm ml-1 font-bold">
                      {{ offer.detail[0].harga | toCurrency }}
                    </span>
                  </div>
                </div>
                <button class="bg-yellow hover:bg-yellow-darker w-full my-2 py-1 rounded-lg font-bold" type="button"
                  @click="openSpecialOfferDetail(offer)">
                  View Detail
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
import Footer from "@/components/Footer";
import { Slugify } from "../utils";

export default {
  name: "SpecialOffers",

  components: {
    Header,
    Breadcrumb,
    Footer,
  },

  data() {
    return {
      search: "",
      offers: [],
      userPosition: {
        latitude: "",
        longitude: "",
      },
    };
  },

  computed: {
    filteredOffers() {
      return this.offers.filter((val) =>
        val.nama.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },

  async created() {
    this.$store.commit("setBreadcrumbItems", [
      { text: "Home", routeName: "Home" },
      { text: "Special for you", routeName: "SpecialOffers" },
    ]);
    this.getLocation();
  },

  methods: {
    showPosition(position) {
      this.userPosition.latitude = position.coords.latitude;
      this.userPosition.longitude = position.coords.longitude;
      this.$store.commit("setCurrentLocation", this.userPosition);
      this.getSpecialForYou();
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition, (_) => {
          this.getSpecialForYou();
        });
      } else {
        this.getSpecialForYou();
      }
    },
    async getSpecialForYou() {
      this.$store.commit("setOverlayLoading", true);
      try {
        const response = await this.$http
          .post(`${this.$apiTripweWeb}/package/list/all`, {
            limit: "8",
            latitude: `${this.userPosition.latitude}`,
            longitude: `${this.userPosition.longitude}`,
            urut: 'jarak'
          });
        if (response.data.status != '200') {
          throw response.data.message;
        }

        this.offers = response.data.data.item ?? [];
      } catch (error) {
        this.$toasted.global.error(error);
      }

      this.$store.commit("setOverlayLoading", false);
    },
    setPackage(pack) {
      this.$store.commit("setPackage", pack);
      var slug = null;
      var trip = null;
      if (pack.tipe == "0") {
        slug = "wateradventure";
      } else if (pack.tipe == "1") {
        slug = "landadventure";
        trip = "offroad";
      } else if (pack.tipe == "2") {
        slug = "skyadventure";
        trip = "onroad";
      }
      this.$router.push({
        name: "CheckoutPaket",
        params: { slug: slug, trip: trip },
      });
    },
    openSpecialOfferDetail(pack) {
      this.$router.push({
        name: "PackageDetail",
        params: { slug: Slugify(pack.nama), pack },
      });
    },
    toggleWishlist(offer) {
      if (offer.idw != "0") {
        this.removeWishlist(offer);
      } else {
        this.addWishlist(offer);
      }
    },
    async addWishlist(offer) {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        user: this.$store.state.user?.id_user ?? "",
        va: this.$store.state.user?.va ?? "",
        id: "-1",
        paket: offer.id,
        toko: offer.id_toko,
        jenis: parseInt(offer.tipe, 10) + 2,
      };

      var res = await this.$http.post(
        "https://tripwe.id/apptripwe/wishlist/simpandata",
        datapost
      );
      if (res.data.status == 102) {
        offer.idw = res.data.data.id.toString();
      }
      this.$store.commit("setOverlayLoading", false);
    },
    async removeWishlist(offer) {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        user: this.$store.state.user?.id_user ?? "",
        va: this.$store.state.user?.va ?? "",
        idwish: offer.idw,
      };

      var res = await this.$http.post(
        "https://tripwe.id/apptripwe/wishlist/hapuswish",
        datapost
      );
      if (res.data.status == 102) {
        offer.idw = "0";
      }
      this.$store.commit("setOverlayLoading", false);
    },
    getLevelImage(level) {
      switch (parseInt(level)) {
        case 1:
          return require("@/assets/icons/ic-level-beginner.svg");
        case 2:
          return require("@/assets/icons/ic-level-intermediate.svg");
        default:
          return require("@/assets/icons/ic-level-advance.svg");
      }
    },
    getLevelText(level) {
      switch (parseInt(level)) {
        case 1:
          return "Beginner";
        case 2:
          return "Intermediate";
        default:
          return "Advance";
      }
    },
  },
};
</script>
<style scoped>
.grd {
  background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0) 37%,
      #1f7882 142%);
}
</style>
